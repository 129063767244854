import React from "react";
import { GatsbyLinkProps, Link } from "gatsby";

const JoLink: React.FC<GatsbyLinkProps<any>> = (props) => {
  if (props.to.includes("http")) {
    return <a href={props.to} {...props} target="_blank" />;
  } else {
    // @ts-ignore
    return <Link {...props} />;
  }
};

export default JoLink;
