import * as React from "react";
import { IAlbum } from "../types/wordpressPage";
import ReactHtmlParser from "react-html-parser";
import styled from "../lib/styled";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { presets } from "../lib/presets";
interface IAlbumProps {
  albums: IAlbum[];
}

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const GalleryThumbnail = styled.div`
  padding: 10px;
  div {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
  }
  p {
    font-size: 0.8rem;
    padding: 5px 0;
    font-weight: bold;
  }
`;
const Wrapper = styled.div`
  width: 33%;

  ${presets.mq.max.tablet} {
    width: 50%;
  }
  ${presets.mq.max.mobile} {
    width: 100%;
  }
  font-family: "Roboto";
  cursor: pointer;

  span.ril-toolbar__item__child.ril__toolbarItemChild {
    font-family: "Roboto";
  }
`;

interface ILightBoxProps {
  album: IAlbum;
}

const AlbumContainer: React.SFC<ILightBoxProps> = (props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState<number>(0);

  const images = props.album.images;
  return (
    <Wrapper>
      <GalleryThumbnail onClick={() => setOpen(true)}>
        <div style={{ backgroundImage: `url(${images[0].image.sourceUrl})` }} />
        <p>{props.album.albumTitle}</p>
      </GalleryThumbnail>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].image.sourceUrl}
          nextSrc={images[(photoIndex + 1) % images.length].image.sourceUrl}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length].image
              .sourceUrl
          }
          imageTitle={images[photoIndex].imageTitle}
          imageCaption={ReactHtmlParser(images[photoIndex].imageDescription)}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Wrapper>
  );
};

const AlbumsPage: React.SFC<IAlbumProps> = (props) => {
  return (
    <GridContainer>
      {props.albums.map((album, i) => {
        return <AlbumContainer key={i} album={album} />;
      })}
    </GridContainer>
  );
};

export default AlbumsPage;
