import React from "react";
import { Link, PageProps } from "gatsby";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import SideBar from "../components/SideBar";
import styled from "../lib/styled";
import { Row, Column } from "hedron";
import { IWordpressPage } from "../types/wordpressPage";
import PageContent from "../components/PageContent";
import ReactHtmlParser from "react-html-parser";
import { GatsbyImage } from "gatsby-plugin-image"
import PodcastContainer from "../components/PodcastContainer";
import AlbumContainer from "../components/AlbumContainer";
import FilmHeader from "../components/FilmHeader";
import { ContentColumn } from "../components/ContentColumn";
import AboutPageCards from "../components/AboutPageCards";
import ContentParser from "../components/ContentParser";

interface Props extends PageProps {
  data: {
    wpPage: IWordpressPage;
  };
}

const ContentHeader = styled.h1`
  font-family: "Bebas Neue";
`;

const Page: React.FC<Props> = (props) => {
  const page = props.data.wpPage;

  const featuredImage =
    page?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData;

  const filterByDate = (year: string) => {
    const podcasts = document.getElementsByClassName("podcast--wrapper");

    for (let index = 0; index < podcasts.length; index++) {
      const podcast = podcasts[index];

      const date = podcast.getAttribute("data-date");

      if (date && date.includes(year)) {
        podcast.className = "podcast--wrapper show";
      } else {
        podcast.className = "podcast--wrapper hide";
      }
    }
  };

  React.useLayoutEffect(() => {
    filterByDate(new Date().getFullYear().toString());
    // filterByDate("2019");
  }, []);

  const getYears = React.useCallback(() => {
    const now = new Date().getUTCFullYear();
    // const now = 2021;
    const to = 2014;

    return Array(now - (to - 1))
      .fill("")
      .map((v, idx) => now - idx) as Array<number>;
  }, []);

  const dontShowTitleOnTemplates = [
    "WpFilmsTemplate",
    "WpTemplate_Store"
  ]

  if (page) {
    return (
      <Layout location={props.location}>
        <>
          {page.template.__typename === "WpFilmsTemplate" &&
            page.wpParent && <FilmHeader parent={page.wpParent} />}
          <Row>
            <SEO title={ReactHtmlParser(page.title)} slug={page.slug} />
            <ContentColumn md={9}>
              {featuredImage && (
                <FeaturedImage>
                  <GatsbyImage
                    style={{ height: "100%", maxHeight: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                    image={featuredImage}
                    alt={ReactHtmlParser(page.title)}
                  />
                </FeaturedImage>
              )}
              <PageContent>
                <>
                  {!dontShowTitleOnTemplates.includes(page.template.__typename) && (
                    <ContentHeader>{ReactHtmlParser(page.title)}</ContentHeader>
                  )}
                  {page.template.__typename ===
                    "WpPodcastsTemplate" && (
                      <PodcastDateFilter>
                        <label>Filter Episodes</label>
                        <select onChange={(e) => filterByDate(e.target.value)}>
                          {getYears().map((year) => {
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </PodcastDateFilter>
                    )}

                  <ContentParser htmlString={page.content} />
                  {page.siteAboutPage && page.siteAboutPage.aboutPageCard && (
                    <AboutPageCards cards={page.siteAboutPage.aboutPageCard} />
                  )}
                  {page.galleries && page.galleries.album && (
                    <AlbumContainer albums={page.galleries.album} />
                  )}
                </>
              </PageContent>
            </ContentColumn>
            <Column md={3}>
              <SideBar />
            </Column>
          </Row>
        </>
      </Layout>
    );
  } else {
    return null;
  }
};

const FeaturedImage = styled.div`
  max-height: 100%;
  height: 258px;
`;

const PodcastDateFilter = styled.div`
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 38px 25px;
  label {
    text-transform: uppercase;
    font-family: "Keep Calm";
    font-size: 13px;
    letter-spacing: 1px;
  }
  select {
    margin: 0 10px;
  }
`;

export default Page;

export const query = graphql`
  query WORDPRESS_PAGE_QUERY($id: String!) {
    wpPage(id: { eq: $id }) {
      databaseId
      id
      title
      slug
      date
      content
      modified
      template {
        __typename
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, height: 260)
            }
          }
        }
      }
      siteAboutPage {
        aboutPageCard {
          name
          position
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 150, height: 150, transformOptions: {cropFocus: CENTER} )
              }
            }
          }
          twitterUrl {
            url
          }
          facebookUrl {
            url
          }
          instagramUrl {
            url
          }
        }
      }
      galleries {
        album {
          albumTitle
          albumDescription
          images {
            imageTitle
            imageDescription
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            filmPageOptions {
              filmPageNavigation {
                navIcon
                navTitle
                navLink
              }
              filmsPageHeroImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
              filmLogo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED)
                  }
                }
              }
            }
          }
        }
      }
      filmPageOptions {
        filmsPageHeroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;
