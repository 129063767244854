import React from "react";
import styled, { css } from "../lib/styled";
import { IAboutPageCard } from "../types/wordpressPage";
import { GatsbyImage } from "gatsby-plugin-image"

import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";

interface Props {
  cards: IAboutPageCard[];
}

const AboutPageCards: React.FC<Props> = (props) => {
  return (
    <Container>
      <h2>Team</h2>
      {props.cards.map((card, i) => {
        return (
          <Card key={i}>
            <ImageWrapper alt={card.name} image={card.image.localFile.childImageSharp.gatsbyImageData} />
            <Info>
              <Name>{card.name}</Name>
              <Position>{card.position}</Position>
              <Description>{card.description}</Description>
              <SocialLinks>
                {card.twitterUrl && (
                  <IconLink href={card.twitterUrl.url}>
                    <FaTwitter />
                  </IconLink>
                )}
                {card.facebookUrl && (
                  <IconLink href={card.facebookUrl.url}>
                    <FaFacebook />
                  </IconLink>
                )}
                {card.instagramUrl && (
                  <IconLink href={card.instagramUrl.url}>
                    <FaInstagram />
                  </IconLink>
                )}
              </SocialLinks>
            </Info>
          </Card>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Card = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 0;
`;
const ImageWrapper = styled(GatsbyImage)`
  border-radius: 100%;
  overflow: hidden;
  width: 150px;
  height: 150px;
  object-fit: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  width: calc(100% - 150px);
`;

const IconLink = styled.a`
  padding: 10px;
  color: white;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
`;

const Name = styled.h3`
  margin: 5px 0;
`;
const Position = styled.p`
  ${({ theme }) => css`
    font-style: italic;
    margin: 0;
    color: ${theme.color.darkOne};
  `}
`;
const Description = styled.p`
  margin: 5px 0;
`;

export default AboutPageCards;
