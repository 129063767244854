import * as React from "react";
import { IWordpressPage } from "../types/wordpressPage";
import styled from "../lib/styled";
import { GatsbyImage } from "gatsby-plugin-image"
import Icon from "./Icon";
import { Sticky } from "react-sticky";
import JoLink from "./Link";
interface IProps {
  parent: IWordpressPage["wpParent"];
}

const FilmHeader: React.SFC<IProps> = (props) => {
  const heroImage = props.parent?.node?.filmPageOptions?.filmsPageHeroImage;
  const filmNav = props.parent?.node?.filmPageOptions?.filmPageNavigation;
  return (
    <Wrapper>
      <Sticky topOffset={20}>
        {({ style, isSticky }) => (
          <Container
            style={style}
            className={isSticky ? "sticky" : "notSticky"}
          >
            <HeroImage className={isSticky ? "sticky" : "notSticky"}>
                <FeaturedImage
                  style={{
                    zIndex: 0,
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  alt="Header image"
                  image={heroImage.localFile.childImageSharp.gatsbyImageData}
                />
            </HeroImage>
            {filmNav && !isSticky && (
              <FilmsNav>
                {filmNav.map((film, i) => {
                  return (
                    <NavLink activeClassName="active" to={`/${film.navLink}`}>
                      {film.navTitle}
                      <Icon icon={film.navIcon} fillColor="white" />
                    </NavLink>
                  );
                })}
              </FilmsNav>
            )}
            {filmNav && isSticky && (
              <SmallNav>
                {filmNav.map((film, i) => (
                  <SmallNavLink
                    activeClassName="active"
                    to={`/${film.navLink}`}
                  >
                    <Icon icon={film.navIcon} fillColor="white" />
                  </SmallNavLink>
                ))}
              </SmallNav>
            )}
          </Container>
        )}
      </Sticky>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  padding-bottom: 0px;
`;
const Container = styled.div`
  display: flex;
  margin-bottom: 0px;
  z-index: 100;
  &.sticky {
    background: ${(props) => props.theme.color.darkFour};
    height: 65px;
    top: 30px !important;
    justify-content: space-between;
  }
`;
const HeroImage = styled.div`
  width: 80%;
  position: relative;
  &.sticky {
    height: 65px;
  }
  &.notSticky {
    min-height: 250px;
  }
`;
const FeaturedImage = styled(GatsbyImage)`
  img {
    object-position: bottom left !important;
  }
`;

const FilmsNav = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallNav = styled.div`
  display: flex;
  flex-direction: row;
`;

const SmallNavLink = styled(JoLink)`
  background: ${(props) => props.theme.color.accent};
  color: white;
  cursor: pointer;
  font-family: "Keep Calm";
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 8px 15px;
  font-size: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:before {
    position: absolute;
    content: "";
    left: -15px;
    border-top: 65px solid transparent;
    border-right: 15px solid ${(props) => props.theme.color.accent};
    top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &.active,
  &:hover {
    color: ${(props) => props.theme.color.darkFour};
    background: white;
    &:before {
      border-right: 15px solid white;
    }
    svg,
    path {
      fill: ${(props) => props.theme.color.darkFour};
    }
  }
`;

const NavLink = styled(JoLink)`
  background: ${(props) => props.theme.color.accent};
  color: white;
  cursor: pointer;
  font-family: "Keep Calm";
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 8px 15px;
  margin-bottom: 4px;
  font-size: 12px;
  min-width: 280px;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    margin-left: ${(props) => props.theme.sizes.spacing.s};
  }
  &:before {
    position: absolute;
    content: "";
    left: -15px;
    border-top: 41px solid transparent;
    border-right: 15px solid ${(props) => props.theme.color.accent};
    top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &.active,
  &:hover {
    color: ${(props) => props.theme.color.darkFour};
    background: white;
    &:before {
      border-right: 15px solid white;
    }
    svg,
    path {
      fill: ${(props) => props.theme.color.darkFour};
    }
  }
`;

export default FilmHeader;
